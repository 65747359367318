import React, { Component } from 'react';
import style from './teamSlider.module.scss';
import Slider from 'react-slick';
import Arrow from 'components/events/arrow';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import Image from 'components/image';
import Icon from 'components/icon';
import parse from 'html-react-parser';

class TeamSlider extends Component {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.state = {
			leftDisabled: true,
			rightDisabled: false,
			currentSlide: this.props.slide,
		}
		this.node = null;
	}

	handleClick(direction) {
		if (direction === -1) this.slider.slickPrev();
		else this.slider.slickNext();
	}

	handleChange(slide) {
		const hasNext = (parseInt($(this.node).find('.slick-slide.slick-active').last().data('index')) < $(this.node).find('.slick-slide').length - 1 ? true : false);
		this.setState({
			leftDisabled: (slide === 0),
			rightDisabled: !hasNext,
		});
	}

	componentDidMount() {
		this.node = ReactDOM.findDOMNode(this);
		this.numSlides = this.props.team.length;
		this.slider.slickGoTo(this.props.slide);
	}

	render() {
		const team = this.props.team;
		const slide = this.props.slide;
		const slick = {
			dots: false,
			infinite: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			className: style.slick,
			arrows: false,
			ref: slider => (this.slider = slider),
			beforeChange: (prev, next) => {
				this.setState({ currentSlide: next });
			},
			afterChange: this.handleChange,
		}
		const leftDisabled = this.state.leftDisabled;
		const rightDisabled = this.state.rightDisabled;
		function padLeadingZeros(num, size) {
			var s = num + "";
			while (s.length < size) s = "0" + s;
			return s;
		}

		return (
			<section className={style.team} >
				<div className="grid-container">
					<div className={`${style.upper} grid-x grid-margin-x`} style={{ alignItems: "center" }}>
						<div className="cell small-12 medium-8">
							<div onClick={this.props.clickHandle} className={`${style.backtoteam}`}><Icon className={style.backArrow} icon="arrow-left-solid" /> {this.props.closeText ? this.props.closeText : "Back To Our Team"}</div>
						</div>
						{this.numSlides > 1 && <div className={`${style.arrows} cell medium-offset-2 medium-2`}>
							<Arrow direction="left" title="Go to previous slide of events." onClick={this.handleClick} disabled={leftDisabled} />
							<Arrow direction="right" title="Go to next slide of events." onClick={this.handleClick} disabled={rightDisabled} />
						</div>}
					</div>
					<div className="grid-x grid-margin-x">
						<div className={`${style.sliderContainer} cell small-12`}>
							<Slider ref={slider => (this.slider = slider)} {...slick}>
								{team.map((member, index) => (
									<div key={index} className={style.slide}>
										<div className="grid-container">
											<div className="grid-x grid-margin-x">
												<div className="cell medium-4 medium-offset-1">
													<div className={style.imageWrapper}>
														<Image width="1000" height="1000" fit="crop" imagePadding="100%" src={member.object.photo} alt={`${member.object.firstName} ${member.object.lastName}`} />
													</div>
												</div>
												<div className={`${style.memberDetails} cell medium-6`}>
													<div className={style.position}>{member.object.position} | {this.props.teamName}</div>
													<div className={style.fullName}>{member.object.firstName} {member.object.lastName}</div>
													<div className={member.object.bio ? style.bio : 'hide'}>{member.object.bio && parse(member.object.bio)}</div>
													{member.object.email && <div className={style.email}><a href={`mailto:${member.object.email}`}>{member.object.email}</a></div>}
												</div>
											</div>
										</div>
									</div>
								))}
							</Slider>
							<div className={style.counter}><span className={style.top}>{padLeadingZeros(this.state.currentSlide + 1, 2)}</span><span className={style.bottom}>{padLeadingZeros(this.numSlides, 2)}</span></div>
						</div>
					</div>
				</div>
			</section>
		)
	}
}

export default TeamSlider;