import React, { useEffect, useState } from "react"
import { graphql } from 'gatsby';
import FoundationLayout from 'templates/Foundation';
const Foundation = ({ data, pageContext }) => {
	let page = null;
	if (data.allContentPage && data.allContentPage.edges.length) page = data.allContentPage.edges[0].node;

	const [newPage, setPage] = useState(page);

	useEffect(() => {
		fetch(`${process.env.GATSBY_ZMS_API_URL}content/page/get/?uri=${page.uri}`)
			.then(response => response.json())
			.then(({ data }) => {
				if (data.length) {
					let page = data[0];
					setPage(page);
				}
			})
			.catch(err => console.log(err));
	}, [page.uri]);

	page = newPage;

	data.allContentPage.edges = [{ node: page }]

	return <FoundationLayout data={data} />
}
export default Foundation;

export const query = graphql`
	query ($lang: String) {
		allContentPage(filter: {uri: {eq: "/foundation/"}, lang: {eq: $lang}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
			edges {
				node {
					id
					uri
					content {
						main
					}
					lang
					pageTitle
					subTitle
					pullQuote
					pageCopy
					headline
					link
					linkText
					danosFoundation
					danosFooterTab
					headerImage
				}
			}
		}
		allFoundationCallout(filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
			edges {
				node {
					title
					subTitle
					content
					sidebarTitle
					sidebarContent
					buttons
				}
			}
		}
		allFoundationFaq(filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
			edges {
				node {
					title
					body
				}
			}
		}
		allFoundationValue(filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
			edges {
				node {
					title
					content
					icon
				}
			}
		}
		allBlogPost(sort: {order: DESC, fields: showDate}, filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
			edges {
				node {
					_model
					id
					photo
					title
					permalink
					uri
					intro
					showDate
				}
			}
		}
		allBlogTopic(sort: {order: ASC, fields: name}, filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
			edges {
				node {
					id
					name
				}
			}
		}
		allStaffPerson(filter: {Team: {elemMatch: {object: {id: {eq: "637bd58b42ef88cd99f84e41"}}}}}) {
			edges {
				node {
					id
					bio
					firstName
					email
					lastName
					position
					photo
				}
			}
		}
	}
`