import React, { Component } from "react";
import style from './staffGrid.module.scss';
import Image from 'components/image';
// import Link from 'components/link';
import { Transition, animated } from 'react-spring/renderprops';
import TeamSlider from './teamSlider';

export default class StaffGrid extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: false,
			member: null,
		}
		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}
	openModal(index) {
		this.setState({
			modal: true,
			member: index,
		});
	}
	closeModal() {
		this.setState({
			modal: false
		})
	}
	render() {
		const modal = this.state.modal;
		return (
			<>
				<section className={style.team}>
					<div className={`grid-container ${style.teamWrapper}`}>
						<div className="grid-x grid-padding-x medium-up-2 large-up-4">
							{this.props.staff && this.props.staff.map((member, index) => (								<div role="presentation" onClick={() => this.openModal(index)} key={index} className={`${style.teamMember} cell`}>
									<div className={style.imageWrapper}>
										<Image width="1000" height="1000" imagePadding="100%" fit="crop" src={member.object.photo} alt={`${member.object.firstName} ${member.object.lastName}`} />
									</div>
									<div className={style.position}>{member.object.position}</div>
									<div className={style.fullName}>{member.object.firstName} {member.object.lastName}</div>
								</div>
							))}
						</div>
						{/* <hr className="show-for-medium" /> */}
					</div>
				</section>
				<Transition
					native
					items={modal}
					from={{ opacity: 0, visibility: 'hidden', left: '-100%' }}
					enter={{ opacity: 1, visibility: 'visible', left: '0' }}
					leave={{ opacity: 0, visibility: 'hidden', left: '-100%' }}>
					{modal => modal && (props =>
						<animated.div style={props} className={style.modal}>
							<div className={`grid-container ${style.innerModal}`}>
								<div className={`grid-x grid-padding-x`}>
									<div className={`cell`}>
										<TeamSlider closeText={this.props.closeText} teamName={this.props.teamName} clickHandle={this.closeModal} team={this.props.staff} slide={this.state.member} />
									</div>
								</div>
							</div>
						</animated.div>
					)
					}
				</Transition>
			</>
		)
	}
}