import React, { Component } from "react";
import AnchorLink from 'react-anchor-link-smooth-scroll';

import Background from 'components/background';
import Layout from "templates/basic/";
import Icon from "components/icon";
import Intro from 'components/intro';
import Accordion from 'components/accordion';
import Link from 'components/link';
import SearchGrid from 'components/searchgrid';
import StaffGrid from 'components/staffGrid';
import SEO from 'components/seo';

import zparse from 'helpers/parse';

import style from 'templates/Foundation/Foundation.module.scss';

export default class FoundationLayout extends Component {
	constructor(props) {
		super(props);
		this.page = null;
		if (props.data.allContentPage && props.data.allContentPage.edges.length) this.page = props.data.allContentPage.edges[0].node;
		this.callouts = [];
		if (props.data.allFoundationCallout && props.data.allFoundationCallout.edges.length) this.callouts = props.data.allFoundationCallout.edges;
		this.values = null;
		if (props.data.allFoundationValue && props.data.allFoundationValue.edges.length) this.values = props.data.allFoundationValue.edges;
		this.faqs = null;
		if (props.data.allFoundationFaq && props.data.allFoundationFaq.edges.length) this.faqs = props.data.allFoundationFaq.edges;
		this.state = {
			callouts: this.callouts,
			navigation: [],
			forceUpdate: false,
			height: 0,
		}
		this.subnavToggle = this.subnavToggle.bind(this);
	}
	subnavToggle() {
		this.setState({height: 0});
		if (document.getElementById('foundationSubnav')) {
			var height = document.getElementById('foundationSubnav').clientHeight + 10;
			(this.state.height === 0) ? this.setState({ height: height }) : this.setState({ height: 0 });
		}
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevState.forceUpdate !== this.state.forceUpdate) {
			this.forceUpdate();
		}
	}
	componentDidMount() {
		this.callouts.forEach((callout,index) => {
			let cleanTitle = callout.node.title;
			cleanTitle = cleanTitle.toLowerCase().replace(" ", "_");
			let navItem = {};
			navItem.title = callout.node.title;
			navItem.link = cleanTitle;
			this.state.navigation.push(navItem);
		});
		this.setState({
			forceUpdate: true,
		})
	}
	render() {
		let searchCTA = 'Search';
		const topics = this.props.data.allBlogTopic.edges;
		const committee = this.props.data.allStaffPerson.edges;
		let teamLeads = committee.map((team,index) => ({ object: team.node }));
		var topicFilter = [];
		var tFilter = {};
		topics.forEach(cf =>{
			tFilter = { value: cf.node.id, label: cf.node.name };
			topicFilter.push(tFilter);
		})

		const filterOptions = [
			{
				name: 'topic',
				placeholder: 'Topic',
				label: 'Topic',
				options: topicFilter
			},
			{
				name: 'type',
				placeholder: 'Source',
				label: 'Source',
				options: [
					{ value: 'danos', label: 'The Pipeline Newsletter' },
					{ value: 'foundation', label: 'Danos Foundation' }
				]
			}
		];
		let allData = this.props.data.allBlogPost.edges;
		const danosCares = [{ image: "/userfiles/Foundation Forms/foundation_module_image1-01.jpeg", title: "Danos Foundation", preTitle: "", description: "The Danos Foundation impacts and improves the communities where we live and work by mirroring the Purpose and Values of Danos. Within the guidelines of the Foundation’s Purpose and Values, we leverage our resources of time, talent and money to support and promote involvement in activities and initiatives to help resolve community challenges in the following core areas: Education, Healthcare, Environment or Welfare." }];
		function Callout({ data, index, total }) {
			let cleanTitle = data.title;
			cleanTitle = cleanTitle.toLowerCase().replace(" ", "_");
			return (
				<section id={cleanTitle} className={[style.calloutContainer, index === total-1 ? style.lastCallout : ''].join(' ')}>
					<div className="grid-container">
						<div className="grid-x grid-margin-x">
							<div className={`${index % 2 ? 'medium-offset-4' : ''} cell small-12`}>
								<h3 className={style.calloutTitle}>{data.title}</h3>
							</div>
						</div>
						<div className="grid-x grid-margin-x">
							<div className={`${index % 2 ? style.orderLast : style.orderFirst} cell small-12 ${data.sidebarContent === null ? 'medium-12' : 'medium-8'}`}>
								<h4 className={style.calloutSubTitle}>{data.subTitle}</h4>
								{ zparse(data.content) }
							</div>
							<div className={`${index % 2 ? style.orderFirst : style.orderLast} cell small-12 medium-4 ${data.sidebarContent === null ? 'hide' : ''}`}>
								<div className={style.calloutSidebar}>
									<h3 className={style.calloutSidebarTitle}>{data.sidebarTitle}</h3>
									{data.sidebarContent && zparse(data.sidebarContent) }
								</div>
							</div>
						</div>
						<div className="grid-x grid-margin-x">
							<div className={`${style.buttons} cell ${index % 2 && data.sidebarContent !== null ? 'medium-offset-4' : ''}`}>
								{data.buttons.map((button, index) => (
									<Link className={`${style.button} button primary`} to={button.buttonLink}>{button.buttonText}</Link>
								))}
							</div>
						</div>
						<hr className={index === total-1 ? 'hide' : ''} />
					</div>
				</section>
			);
		}
		return (
			<>
				<Layout image={this.page.headerImage} preTitle={this.page.pageTitle} title={this.page.subTitle} link={this.page.link} linkText={this.page.linkText} enableDanosFoundation={this.page.enableDanosFoundation} enableFooterTabs={this.page.danosFooterTab}>
					<section className={style.subnavContainer}>
						<div className="grid-container">
							<div className="grid-x grid-margin-x">
								<div className="cell small-12">
									<p onClick={this.subnavToggle} className={[style.toggle,"hide-for-medium"].join(' ')}>Menu <Icon className={[style.icon, (this.state.height > 0) ? style.active : ''].join(' ')} icon="chevronDown" /></p>
									<div className={style.subnavInner} style={{height: this.state.height}}>
										<ul id="foundationSubnav" className={style.subnav}>
											{this.state.navigation.map((item, index) => (
												<li key={index}><AnchorLink offset={() => 100} href={`#${item.link}`}>{item.title}</AnchorLink></li>
											))}
											<li><AnchorLink offset={() => 70} href="#foundation_news">Foundation News</AnchorLink></li>
											<li><AnchorLink offset={() => 100} href="#danos_faqs">Foundation FAQs</AnchorLink></li>
											<li>
												<form name="PrePage" method="post" action="https://Simplecheckout.authorize.net/payment/CatalogPayment.aspx" target="_blank">
													<input type="hidden" name="LinkId" value="9f3787b3-f1ad-4ce9-9b80-1f0ed7c5b7fa" />
													<input className={style.donateButton} type="submit" value="Donate" />
												</form>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</section>
					<Intro pullQuote={this.page.pullQuote} headline={this.page.headline} pageCopy={this.page.pageCopy} />
					<div style={{borderBottom: 'solid 1px #979797'}} />
					<section id="danos_values" className={style.valuesContainer}>
						<div className="grid-container">
							<div className="grid-x grid-margin-x">
								<div className="cell text-center">
									<h3 className={style.valuesTitle}>Danos Foundation</h3>
								</div>
							</div>
							<div className="grid-x grid-margin-x medium-up-3">
								{this.values.map((value, index) => (
									<div className={`${style.value} cell text-center`} key={index}>
										<div className={value.node.icon ? style.valueIconHolder : 'hide'}>
											<Background className={value.node.icon ? style.valueIcon : 'hide'} image={value.node.icon} />
										</div>
										<h5>{value.node.title}</h5>
										<div className={style.valueContent}>
											{ zparse(value.node.content) }
										</div>
									</div>
								))}
							</div>
							<div className="grid-x grid-margin-x" style={{marginTop: '30px'}}>
								<div className="cell small-12 text-center">
									<form name="PrePage" method="post" action="https://Simplecheckout.authorize.net/payment/CatalogPayment.aspx" target="_blank">
										<input type="hidden" name="LinkId" value="9f3787b3-f1ad-4ce9-9b80-1f0ed7c5b7fa" />
										<input className={`${style.valuesButton} button`} type="submit" value="Donate" />
									</form>
								</div>
							</div>
						</div>
					</section>
					{this.state.callouts.map((callout, index) => (
						<Callout total={this.callouts.length} data={callout.node} index={index} key={index} />
					))}
					<section id="danos_faqs" className={style.faqContainer}>
						<div className="grid-container">
							<div className="grid-x grid-margin-x">
								<div className="cell">
									<h3 className={style.faqTitle}>FAQ</h3>
								</div>
							</div>
							<div className="grid-x grid-margin-x">
								<div className="cell medium-8">
									<h4 className={style.faqSubTitle}>Overview &amp; Guidelines</h4>
									{this.faqs.map((faq, index) => (
										<Accordion title={faq.node.title} body={faq.node.body} />
									))}
								</div>
								<div className="cell medium-4">
									<div className={style.faqSidebar}>
										<h3 className={style.faqSidebarTitle}>Foundation Contact</h3>
										<p>3878 West Main Street <br />Gray, LA 70359<br />Tel: 985-219-3438<br /><a href="mailto:foundation@danos.com">foundation@danos.com</a></p>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section id="foundation_news">
						<SearchGrid defaultQuery={{type: "foundation"}} apiController="blog/post" title="" description="" initialData={allData} searchCTA={searchCTA} filterOptions={filterOptions} />
					</section>
					{teamLeads.length > 0 &&
						<section className={style.team}>
							<div style={{ marginBottom: "99px" }} className="grid-container">
								<div className="grid-x grid-margin-x">
									<div className="cell small-12">
										<h2 className="text-center underline">Foundation Committee</h2>
									</div>
								</div>
							</div>
							<StaffGrid closeText="Close" staff={teamLeads} />
						</section>
					}
				</Layout>
				<SEO title={this.page.pageTitle} />
			</>
		)
	}
}